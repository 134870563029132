import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function getKeywords(keywords) {
  if (keywords && keywords.length) {
    if (typeof keywords === 'string') {
      return  [{
        name: `keywords`,
        content: keywords,
      }]
    }
    if (Array.isArray(keywords)) {
      return [{
        name: `keywords`,
        content: keywords.join(`, `),
      }]
    }
  }

  return []
}

function getImage(image) {
  if (image) {
    return [{
      property: `og:image`,
      content: image,
    }, {
      name: `twitter:image`,
      content: image,
    }]
  }

  return []
}

function Seo({
  description,
  lang,
  meta,
  keywords,
  title,
  url,
  type,
  image,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const siteTitle = data.site.siteMetadata.title
        const siteDescription = data.site.siteMetadata.description

        const documentTitle = title ? `${title} | ${siteTitle}` : siteTitle
        const metaTitle = title || siteTitle

        description = description || siteDescription

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={documentTitle}
            meta={[
              {
                name: `description`,
                content: description  ,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: description  ,
              },
              {
                property: `og:type`,
                content: type,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: description  ,
              },
              {
                name: `twitter:url`,
                content: url,
              },
            ]
              .concat(getKeywords(keywords))
              .concat(getImage(image))
              .concat(meta)}
          >
            <link rel='canonical' href={url} />
          </Helmet>
        )
      }}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  type: 'website',
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export default Seo

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
