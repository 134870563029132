import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { rhythm, scale } from '../utils/typography'
import { PURPLE } from '../constants/colors';

function Layout({ location, title, children }) {
  const isRoot = location.pathname === '/'; // TODO: on root, site title should be h1

  return (
    <StaticQuery
      query={layoutQuery}
      render={data => (
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(30),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>
            <Link to='/'>
              <h3 style={{
                fontWeight: 700,
                letterSpacing: rhythm(0.1),
                textTransform: `uppercase`,
                color: PURPLE,
              }}>
                {data.site.siteMetadata.title}
              </h3>
            </Link>
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={location.pathname}
            >
              <h1 
                id="title"
                style={{
                  ...scale(1.5),
                  marginBottom: rhythm(1.5),
                  marginTop: 0,
                }}
              >
                {title}
              </h1>
            </Link>
          </header>
          {children}
          <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
        </div>
      )}
    />
  )
}

const layoutQuery = graphql`
  query DefaultLayoutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Layout;
